import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import BlogCards from '../../components/blogCards'
import BrandSection from '../../components/brandSection'
import Footer from '../../components/footer'
import PricingCard from '../../components/pricingCard'
import ServiceCards from '../../components/serviceCards'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'
import { Button } from 'react-bootstrap'
import { useState } from 'react'

const Landing1 = () => {
    return (
        <>
        <Helmet>
            <title>ITMG - Elevate Your Business with Custom eCommerce Solutions</title>
            <meta name="description" content="ITMG offers premium web development services to build custom eCommerce websites. Drive conversions and scale your business with our expert solutions." />
            <meta name="keywords" content="eCommerce web development, custom websites, ITMG, online stores, web design, business solutions, ITMG web development" />
            <meta property="og:title" content="ITMG - Elevate Your Business with Custom eCommerce Solutions" />
            <meta property="og:description" content="From custom online stores to scalable eCommerce platforms, ITMG helps businesses succeed in the digital marketplace." />
            <meta property="og:image" content="URL_to_your_image" />
            <meta property="og:url" content="https://yourwebsite.com" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="canonical" href="https://yourwebsite.com" />
        </Helmet>
            <NavBar/>
            <div className='landing-1'>
                <Hero />
                <Services />
                <About />
                <Counters />
                <Pricing />
                {/* <Blog /> */}
                <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {

    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/brand1.svg"
        },
        {
            name: "brand2",
            imgUrl: "assets/brand2.svg"
        },
        {
            name: "brand3",
            imgUrl: "assets/brand3.svg"
        },
        {
            name: "brand4",
            imgUrl: "assets/brand4.svg"
        },
        {
            name: "brand5",
            imgUrl: "assets/brand5.svg"
        }
    ]

    return (
        <>
            <section className='section-global no-border bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <h1>Elevate Your Ecommerce</h1>
                            <p className='txt-1 mt-16 me-lg-5'>We specialize in crafting powerful ecommerce platforms designed to help you scale your business and drive conversions.</p>
                            <form className='mt-20 me-lg-5'>
                                <div className="cta-form d-flex align-items-center justify-content-between">
                                    <div className="cta-input">
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter your email" />
                                    </div>
                                    <div className="cta-btn">
                                        <button type="submit" className="btn btn-primary btn-lg">Get a demo</button>
                                    </div>
                                </div>
                            </form>
                            <p className='mt-16 mb-0 txt-4'>or checkout our <a className='white-link' href='#pricing'>pricing plans.</a></p>
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center text-center">
                            <div style={{maxWidth: '450px'}}>
                                <img src={require('./assets/hero_image.png')} className="img-fluid mt-5 mt-lg-0" alt='hero' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BrandSection brands={brands} src={'landing1'} bg="bg-shade-1" light bordered/>
        </>
    )
}

const Services = () => {
    const services = [
        {
            name: 'Custom eCommerce Solutions',
            info: 'Tailored platforms designed to meet the unique needs of your business and enhance customer experience.',
            link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Payment Gateway Integration',
            info: 'Seamless and secure payment processing, ensuring smooth transactions for your customers.',
            link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Inventory Management Systems',
            info: 'Automated inventory tracking that keeps your eCommerce store efficient and up-to-date.',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Advanced SEO & Marketing',
            info: 'Boost your store’s visibility and drive organic traffic with our expert SEO and marketing solutions.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ]
    
    return(
        <>
            <section className="section-global">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Services</div>
                            <h2>Innovative eCommerce Solutions for Your Business</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"  key={i}>
                                    <ServiceCards data={service} src="landing1"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const About = () => {
    const timeline = [
        {
            title: 'Discovery',
            text: 'We conduct thorough market research to identify key opportunities and optimize your eCommerce strategy.'
        },
        {
            title: 'Development',
            text: 'Our team designs and builds robust eCommerce platforms tailored to your business, ensuring seamless user experiences and high performance.'
        },
        {
            title: 'Growth',
            text: 'We implement continuous integration and agile methodologies, enabling your eCommerce store to scale efficiently while adapting to market changes.'
        }
    ]
    
    return(
        <>
            <section id="about" className='section-global bg-shade-1'>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 text-center align-self-center">
                            <img src={require('./assets/about.png')} className="img-fluid about-banner-image" alt="how we work" />
                        </div>
                        <div className="col-lg-6">
                        <div className="section-tag mb-8">Our Process</div>
                        <h2 className='mb-16'>Get Streamlined with ITMG</h2>
                        <p className='txt-1 mb-16'>At ITMG, we build cutting-edge eCommerce platforms that drive growth and simplify operations, allowing your business to scale with ease.</p>
                            <div className="time-line">
                                {timeline.map((e,i)=> 
                                    <div className="content" key={i}>
                                        <div className="index">{i+1}</div>
                                        <h3 className='mb-8 mb-0'>{e.title}</h3>
                                        <p className='txt-2 mb-0'>{e.text}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Counters = () => {
    const counter_data = [
        {
            count: '1,000+',
            text: 'Successful eCommerce Websites Launched'
        },
        {
            count: '250%',
            text: 'Average Revenue Growth for Clients'
        },
        {
            count: '50+',
            text: 'Custom Solutions Developed'
        },
        {
            count: '500K+',
            text: 'Products Managed Across Platforms'
        }
    ];
    
    return (
        <>
            <section id="counter" className='counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Pricing = () => {
    const [start, setStart] = useState(3);
    const pricing_data = [
        {
            title: 'Basic Ecommerce Package',
            text: 'Perfect for startups and small businesses.',
            price: '$1,000',
            period: '',
            included: [
                'Custom website design',
                'Payment gateway integration',
                'Responsive design',
            ],
            not_included: [
                'Content management system',
                'Product management features',
                'Advanced SEO services',
                'Ongoing maintenance',
                'Advanced analytics',
            ],
            buttonLabel: 'Contact sales'
        },
        {
            title: 'Standard Ecommerce Package',
            text: 'For growing businesses looking to enhance their online presence.',
            price: '$3,000',
            period: '',
            included: [
                'Custom website design',
                'Payment gateway integration',
                'Responsive design',
                'Content management system',
                'Product management features',
            ],
            not_included: [
                'Advanced SEO services',
                'Ongoing maintenance',
                'Advanced analytics',
            ],
            buttonLabel: 'Contact sales',
            featured: true
        },
        {
            title: 'Premium Ecommerce Package',
            text: 'Comprehensive solution for large enterprises.',
            price: '$5,000',
            period: '',
            included: [
                'Custom website design',
                'Payment gateway integration',
                'Responsive design',
                'Content management system',
                'Product management features',
                'Advanced SEO services',
                'Ongoing maintenance',
                'Advanced analytics',
            ],
            buttonLabel: 'Contact sales'
        },
        {
            title: 'Personal Package',
            text: 'Perfect for startups and small businesses.',
            price: '$299',
            period: '',
            included: [
                '3 Page Static Website',
                'Mobile Responsive Website',
                'Online Booking Tool',
            ],
            not_included: [
                'Social Media Page Design',
                'Unique Logo Design',
                'Lead Capturing Forms',
                'Newsletter Subscription',
                'Social Media Integration',
            ],
            buttonLabel: 'Contact sales'
        },
        {
            title: 'Influencer Package',
            text: 'For growing businesses looking to enhance their online presence.',
            price: '$499',
            period: '',
            included: [
                '5 Page Static Website',
                'Mobile Responsive Website',
                'Online Booking Tool',
                'Social Media Page Design',
                'Unique Logo Design',
            ],
            not_included: [
                'Lead Capturing Forms',
                'Newsletter Subscription',
                'Social Media Integration',
            ],
            buttonLabel: 'Contact sales',
            featured: true
        },
        {
            title: 'Local Business',
            text: 'Comprehensive solution for large enterprises.',
            price: '$899',
            period: '',
            included: [
                '8-10 Page Dynamic Website',
                'Mobile Responsive Website',
                'Online Booking Tool',
                'Social Media Page Design',
                'Unique Logo Design',
                'Lead Capturing Forms',
                'Newsletter Subscription',
                'Social Media Integration',
            ],
            buttonLabel: 'Contact sales'
        }
    ];
    
    
    return(
        <>
            <section id="pricing" className='section-global'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Pricing</div>
                            <h2>Tailored Plans for Every Ecommerce Journey</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className='d-flex justify-content-center'>
                            <Button onClick={() => setStart(3)} className='m-1' variant="secondary" size="lg">
                                Business
                            </Button>
                            <Button onClick={() => setStart(0)} className='m-1'variant="primary" size="lg">
                                Ecommerce
                            </Button>
                        </div>
                        {pricing_data.splice(start,3).map((e,i)=> 
                            <div className="col-lg-4 col-md-6 gy-4" key={i}>
                                <PricingCard data={e}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Blog = () => {

    const blog_data = [
        {
            category: 'leadership',
            title: 'How ITMG helps you make values visible in your business.',
            date: '09 Sept, 2021',
            imgUrl: 'assets/blog_thumb1.jpg',
            link: '/blog-article'
        },
        {
            category: 'announcement',
            title: "Values (What They Are, Why They're Important)",
            date: '09 Sept, 2021',
            imgUrl: 'assets/blog_thumb2.jpg',
            link: '/blog-article'
        },
        {
            category: 'culture',
            title: 'How Our Tools Will Change The Way You Create Content',
            date: '09 Sept, 2021',
            imgUrl: 'assets/blog_thumb3.jpg',
            link: '/blog-article'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-green'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Blog</div>
                            <h2>Stay updated with our insider affairs</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/blog' className='btn btn-outline btn-arrow ms-lg-auto'>
                                Go to blog
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>

                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <BlogCards data={blog_data} src='landing1'/>
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
            <div style={{ height: "70vh", overflow: 'hidden' }}>
                <iframe
                    id="contact"
                    src="https://calendly.com/gavriel-itmginc/30min"
                    width="100%"
                    height="100%"
                    frameborder="0"
                />
            </div>
        </>
    )
}

export default Landing1